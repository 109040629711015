<template>
  <draggable class="dragLevel" :list="items" item-key="doc.id" handle=".drag-handler" :group="{ name: 'sections' }" :disabled="disabled" >
    <template #item={element}>
      <div>
        <tree-item :key="element.editorKey" :item="element" />
        <nested-draggable v-if="element.hasOwnProperty('items')" :items="element.items" :disabled="disabled"/>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import TreeItem from './TreeItem.vue'
export default {
  name: 'nested-draggable',
  components: { 
    draggable, TreeItem
   },
  props: {
    items: {
      required: true,
      type: Array
    },
    disabled: {
      required: false,
      type: Boolean
    }
  }
}
</script>

<style>
.dragLevel:not(:first-child) {
    padding-left: 1.5rem;
}
</style>