<template>
  <div class="grid" :class="{ 'editing-bg': isEditing, 'isDisabled': isDisable, 'text-danger' : hasError }">
    <DragHandler />
    <div class="item-icon col-fixed flex align-items-center"><Icon :icon="displayIcon(typeName)" /></div>
    <div class="item-name col flex align-items-center justify-content-start">{{name}} <i v-if="isDisable" class="pi pi-eye-slash pl-3"></i></div>
    <div class="align-items-center flex col justify-content-end"><i v-if="hasError" class="mr-1 pi pi-exclamation-triangle"></i></div>
    <div class="col-fixed flex align-items-center justify-content-end">
      <Button @click="editSection(doc)" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-outlined" />
    </div>
  </div>
</template>

<script>
import { inject, computed } from 'vue'
import { useFlowEditor } from '@/compositions'
import DragHandler from '@/components/base/DragHandler.vue'
import { Icon, addIcon } from '@iconify/vue/dist/offline'
import iconFolder from '@iconify-icons/mdi/folder'
import iconMedia from '@iconify-icons/mdi/filmstrip'
import iconPXapp from '@iconify-icons/mdi/application-brackets-outline'
import iconExternal from '@iconify-icons/mdi/open-in-new'
import iconUnknown from '@iconify-icons/mdi/help-rhombus-outline'

addIcon('unknown', iconUnknown)
addIcon('media', iconMedia)
addIcon('pxapp', iconPXapp)
addIcon('external', iconExternal)
addIcon('folder', iconFolder)

export default {
  name: 'tree-item',
  props: ['item'],
  components: {
    DragHandler,
    Icon
  },
  setup (props) {
    const editSection = inject('editSection')
    const currentSection = inject('currentSection')

    const { fieldGet, hasError } = useFlowEditor(props.item.editorKey)

    const id = computed({
      get () { return fieldGet('id') }
    })

    const name = computed({
      get () { return fieldGet('name') }
    })

    const isEnabled = computed({
      get () { return fieldGet('isEnabled') }
    })

    const typeName = computed({
      get () { return fieldGet('type.name') }
    })

    const isEditing = computed(() => {
      return currentSection.value?.id === id.value
    })

    const isDisable = computed(() => {
      return !isEnabled.value
    })

    const doc = computed(() => {
      return {
        ...props.item.doc,
        items: props.item.items
      }
    })

    const displayIcon = (type) => {
      switch(type) {
        case 'PXApp':
          return 'pxapp'
        case 'Folder':
          return 'folder'
        case 'Media':
          return 'media'
        case 'External':
          return 'external'
        default:
          return 'unknown'
      }
    }
    return {
      editSection,
      currentSection,
      name,
      typeName,
      isEditing,
      isDisable,
      doc,
      displayIcon,
      hasError
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  background: var(--surface-300);
  border-radius: .25rem;
  padding-left: .75rem;
  margin-bottom: 1rem;
}

.grid .p-button-sm {
  height: 1.857rem !important;
  width: 1.857rem !important;
}

.editing-bg {
  background: var(--surface-200);
  border: 1px solid #883cae;
}
.pi-eye-slash {
  font-size: 1.5rem;
}
.isDisabled {
  .col{
    opacity: .5;
  }
}
</style>