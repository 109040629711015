<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Item</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid px-5">

          <div class="field grid">
            <label for="title" :class="{'p-error':titleError, 'col-2 font-bold justify-content-end': true}">{{ $t('forms.title') }}</label>
            <div class="col">
              <InputText id="title" type="text"
                v-model="title"
                :class="{'p-invalid': titleError}"
                :disabled="isLoading"
              />
              <small v-if="titleError" class="p-error">{{titleError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="type" :class="{'p-error':typeError, 'col-2 font-bold justify-content-end': true}">Type</label>
            <div class="col">
              <AutoComplete id="type" type="text"
                v-model="type"
                :suggestions="typesListing"
                dropdown
                @complete="searchType($event)" field="name"
                :class="{'p-invalid': typeError}"
                :disabled="isLoading"
              />
              <small v-if="typeError" class="p-error">{{typeError}}</small>
            </div>
          </div>



        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.add')" icon="pi pi-check" :loading="isLoading" @click="add"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue'
import Message from "primevue/message"
import InputText from "primevue/inputtext"
import AutoComplete from "primevue/autocomplete"
import { useToast } from 'primevue/usetoast'
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { genNewId } from '@/compositions/useFlowEditor'

export default {
  name: 'DialogAddLang',
  props: ['modelValue', 'editorKey'],
  emits: ['sectionAdded', 'update:modelValue'],
  setup: (props, { emit }) => {
    const toast = useToast()
    const pxstream = inject('pxstream')

    const typesListing = ref([])
    const title = ref('')
    const type = ref(null)
    const showIt = ref(false)
    const isLoading = ref(false)
    const errorCaught = ref('')
    
    const rules = {
      title: {
        required
      },
      type: {
        required
      }
    }
    const state = {
      title,
      type
    }

    const v$ = useVuelidate(rules, state)

    const titleError = computed(() => {
      if (v$.value.title.$error) {
        return 'Please enter a title'
      } else{
        return ''
      }
    })
    const typeError = computed(() => {
      if (v$.value.type.$error) {
        return 'Please enter a type'
      } else{
        return ''
      }
    })

    const resetFields = () => {
      v$.value.$reset()
      type.value = null
      title.value = ''
      errorCaught.value = ''
    }
    const searchType = async ({query}) => {
      try {
        const qryBuilder = pxstream.tools.createQueryBuilder()
        qryBuilder.sortField = 'order'
        qryBuilder.setFilterSearch(query)
        const { data } = await pxstream.portalmanager.getMenuItemTypes(qryBuilder.build())
        typesListing.value = data
      } catch (err) {
        toast.add({severity: 'error', summary: 'Failed to get types', detail: err.toString(), life: 4000})
      }
    }
    const add = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      errorCaught.value = ''
      isLoading.value = true

      showIt.value = false
      pxstream.portalmanager.createEmptyMenuItem({name: title.value, type: type.value.id})
      .then(({data}) => {
        data.id = genNewId()
        emit('sectionAdded', data)
      }).catch(({message}) => {
        errorCaught.value = message
      }).finally(() => {
        isLoading.value = false
      })
    }

    watch(() => props.modelValue, (val) => {
      showIt.value = val
    })

    return {
      typesListing,
      title,
      type,
      showIt,
      isLoading,
      errorCaught,
      v$,
      titleError,
      typeError,
      resetFields,
      searchType,
      add
    }
  },
  components: {
    Message, InputText, AutoComplete
  }
}
</script>